const R = Math.PI / 180;

let cityParam = null

/**
 * 指定サイズで文字列を着る
 */
export const clampText = (text, size) => (!text || text.length <= size) ? text : `${text.slice(0, size)}...`

/**
 * K, Mでの金額表記
 */
export const formatCutPrice = (price) => {
  if (!price) return price

  if (price > 1000000) {
    return `${(Math.round(price / 100000) / 10).toFixed(1)}M`
  }
  else {
    return `${Math.round(price / 1000)}K`
  }
}

/**
 * URLを生成
 */
export const generateUrl = (path, format = '.json', clearParams = true) => {
  const locale = document.querySelector('html').getAttribute('lang')
  const url = new URL(location.href)
  const paths = []
  if (locale != 'en') {
    paths.push(locale)
  }
  paths.push(`${path}${format}`)
  url.pathname = paths.join('/')

  if (clearParams) {
    url.search = ''
  }

  return url
}

/**
 * ２点間の距離を取得
 */
export const getDistance = (location1, location2) => 6371 * Math.acos(Math.cos(location1.lat * R) * Math.cos(location2.lat * R) * Math.cos(location2.lng * R - location1.lng * R) + Math.sin(location1.lat * R) * Math.sin(location2.lat * R))

/**
 * 市区町村のパラメータが存在するか
 */
export const hasCityParam = () => {
  if (cityParam == null) {
    const url = new URL(location.href)
    cityParam = url.searchParams.has('city')
  }
  return cityParam
}

/**
 * モバイルからのアクセスか
 */
export const isMobile = () => {
  const ua = navigator.userAgent
  return ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0
}

/**
 * ページのトップへ
 */
export const pageTop = (delay = 500) => $('body, html').animate({ scrollTop: 0 }, delay)

/**
 * Enterキーによるsubmit防止
 */
export const preventEnterSubmit = (SELECTOR = 'form input') => {
  document.querySelectorAll(SELECTOR).forEach((element) => {
    element.addEventListener('keypress', (e) => {
      if ((e.which && e.which == 13) || (e.keyCodee && e.keyCode == 13)) {
        e.preventDefault()
        return false
      }
      return true
    })
  })
}

/**
 * 配列の中身をシャッフルする
 */
export const shuffleArray = (array) => {
  const list = [...array]
  const result = []
  while (list.length > 0) {
    result.push(list.splice(Math.floor(Math.random() * list.length), 1)[0])
  }
  return result
}
